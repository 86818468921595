import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SocialProfile from '../../components/social-profile/social-profile';
import _ from 'lodash';
import FooterWrapper, {
  FooterCol,
  Logo,
  Infos,
  FooterTitle,
  FooterContent,
  Menu,
} from './footer.style';
import LogoImage from '../../images/logo.svg';

import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoYoutube,
} from 'react-icons/io';

const MenuItems = [
  {
    label: 'Accueil',
    url: '/',
  },
  {
    label: 'Application',
    url: 'https://www.mylabel.io/#download',
    external: true,
  },
  {
    label: 'Courses en ligne',
    url: 'https://www.mylabel.io/#download-plugins',
    external: true,
  },
  {
    label: 'Contact',
    url: 'https://www.mylabel.io/#contact',
    external: true,
  },
  {
    label: 'Télécharger',
    url: 'https://www.mylabel.io/dl',
    external: true,
  },
];

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: 'https://www.facebook.com/mylabelcommunity',
  },
  {
    icon: <IoLogoInstagram />,
    url: 'https://www.instagram.com/mylabelapp/',
  },
  {
    icon: <IoLogoTwitter />,
    url: 'https://twitter.com/myLabelApp',
  },
  {
    icon: <IoLogoLinkedin />,
    url: 'https://www.linkedin.com/company/mylabel-app',
  },
  {
    icon: <IoLogoYoutube />,
    url: 'https://www.youtube.com/channel/UCoeNNY4vej_0KZmvodTBCWA',
    tooltip: 'YouTube',
  },
];

type FooterProps = {
  children: React.ReactNode;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  children,
  ...props
}) => {
  const Data = useStaticQuery(graphql`
    query {
      allMarkdownRemark {
        group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `);

  const Category = Data.allMarkdownRemark.group;

  return (
    <FooterWrapper {...props}>
      <FooterCol>
        <Logo>
          <Link to="/">
            <img src={LogoImage} alt="logo" />
          </Link>
        </Logo>

        <Infos>36, quai Fulchiron</Infos>
        <Infos>69005 Lyon - France</Infos>
        <br />
        <Infos>
          Copyright &copy; {new Date().getFullYear()} &nbsp;
          <a href="https://www.mylabel.io"> myLabel</a>
        </Infos>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Liens</FooterTitle>

        <FooterContent>
          {MenuItems.map((item, index) => (
            <Menu key={index}>
              {item.external ? (
                <a href={item.url}>{item.label}</a>
              ) : (
                <Link to={item.url} activeClassName="active-link">
                  {item.label}
                </Link>
              )}
            </Menu>
          ))}
        </FooterContent>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Catégories</FooterTitle>

        <FooterContent>
          {Category.slice(0, 6).map((cat: any, index: any) => (
            <Menu key={index}>
              <Link to={`/category/${_.kebabCase(cat.fieldValue)}`}>
                {cat.fieldValue}
              </Link>
            </Menu>
          ))}
        </FooterContent>
      </FooterCol>

      <FooterCol>
        <FooterTitle>Suivez nos actualités</FooterTitle>

        <SocialProfile items={SocialLinks} />
      </FooterCol>
    </FooterWrapper>
  );
};

export default Footer;
