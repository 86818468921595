const colors = {
  transparent: 'transparent', // 0
  black: '#292929', // 1
  white: '#f8f8ff', // 2
  textColor: '#292929', // 3
  lightTextColor: '#d3d3d3', // 4
  inactiveField: '#F7F7F7', // 5
  inactiveColor: '#767676', // 6
  inactiveBG: '#e6e6e6', // 7
  borderColor: '#DBDBDB', // 8
  lightBorderColor: '#ededed', //9
  primary: '#D10068', // 10
  primaryHover: '#D10068', // 11
  textLightColor: '#000', // 3
  background : "#252271", // 12
  secondaryGreen : "#30F299", // 13
  lightBlue : "#e6edff", // 14
};

export default colors;
